import { GImage } from '../../g-image';

import Link from 'next/link';

import { imagePositionByDivision } from './data';
import { FC } from 'react';
import { Division } from '@/Lib/types/division';
import { BannerPathFactory } from '@/Lib/helpers/banner-path-factory';
import { divisionNameNormalizer } from '@/Lib/helpers/division-name-normalizer';

type props = {
  division: Division;
  hasNoMobileVersion?: boolean;
  click?: any;
};

const DivisionCard: FC<props> = ({
  division,
  hasNoMobileVersion = false,
  click,
}) => {
  return (
    <Link key={division.id} href={'/' + division.internalName || ''}>
      <a className="block">
        {/* this noMobileVersion is used in pages like /lp/working-overseas */}
        {hasNoMobileVersion ? (
          <div className="w-full">
            {division && (
              <article className="w-full h-full flex flex-col justify-between cursor-pointer">
                <div>
                  <div className="h-48">
                    <GImage
                      path={BannerPathFactory.fromDivision(division.type)}
                      alt={division.name}
                      transformation="division-cards"
                      classes={'object-center rounded-md'}
                      height="100%"
                      width="100%"
                      hasLoadingBackground
                    />
                  </div>
                  <div className="mt-4 mb-1.5 font-bold text-xl leading-6 text-dark-900">
                    {divisionNameNormalizer(division.name)}
                  </div>
                  <div
                    className="block text-base leading-5 text-dark-700"
                    dangerouslySetInnerHTML={{ __html: division.summary }}
                  />
                </div>
                <div className="block mt-3 space-x-2.5 text-2xs font-bold leading-3">
                  <div className="inline-flex justify-center items-center py-1 px-2 rounded-full bg-purple-700 text-purple-900">
                    {division.stats.age.min}
                    {division.stats.age.max
                      ? '-' + division.stats.age.max
                      : '+'}{' '}
                    y/o
                  </div>
                  <div className="inline-flex justify-center items-center py-1 px-2 rounded-full bg-teal-500 text-teal-900">
                    {division.stats.duration.length.min}-
                    {division.stats.duration.length.max}{' '}
                    {division.stats.duration.type}
                  </div>
                  <div className="inline-flex justify-center items-center py-1 px-2 rounded-full bg-yellow-100 text-yellow-600">
                    {division.destinations.length} Countries
                  </div>
                </div>
              </article>
            )}
          </div>
        ) : (
          <div className="w-36 lg:w-full h-full" onClick={click}>
            {division && (
              <article className="w-full h-full flex flex-col justify-between cursor-pointer">
                <div>
                  <div className="h-48">
                    <GImage
                      path={BannerPathFactory.fromDivision(division.type)}
                      alt={division.name}
                      transformation="division-cards"
                      classes={`${
                        imagePositionByDivision[
                          division.internalName.toLowerCase()
                        ]
                      } lg:object-center rounded-md`}
                      height="100%"
                      width="100%"
                      hasLoadingBackground
                    />
                  </div>
                  <div className="mt-2 lg:mt-4 lg:mb-1.5 font-bold text-xl leading-6 text-dark-900">
                    {divisionNameNormalizer(division.name)}
                  </div>
                  <div
                    className="hidden lg:block text-base leading-5 text-dark-700"
                    dangerouslySetInnerHTML={{ __html: division.summary }}
                  />
                </div>
                <div className="flex-grow"></div>
                <div className="hidden lg:block mt-3 space-x-2.5 text-2xs font-bold leading-3">
                  <div className="inline-flex justify-center items-center py-1 px-2 rounded-full bg-purple-700 text-purple-900">
                    {division.stats.age.min}
                    {division.stats.age.max
                      ? '-' + division.stats.age.max
                      : '+'}{' '}
                    y/o
                  </div>
                  <div className="inline-flex justify-center items-center py-1 px-2 rounded-full bg-teal-500 text-teal-900">
                    {division.stats.duration.length.min ===
                    division.stats.duration.length.max ? (
                      <span>
                        {division.stats.duration.length.max}{' '}
                        {division.stats.duration.type}
                      </span>
                    ) : (
                      <span>
                        {division.stats.duration.length.min}-
                        {division.stats.duration.length.max}{' '}
                        {division.stats.duration.type}
                      </span>
                    )}
                  </div>
                  <div className="inline-flex justify-center items-center py-1 px-2 rounded-full bg-yellow-100 text-yellow-600">
                    {division.destinations.length}
                    {division.destinations.length > 1 ? (
                      <span className="ml-0.5">Countries</span>
                    ) : (
                      <span className="ml-0.5">Country</span>
                    )}
                  </div>
                </div>
              </article>
            )}
          </div>
        )}
      </a>
    </Link>
  );
};

export { DivisionCard };
