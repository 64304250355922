import { ProductCardSmall } from '@/Components/cards/product-card-small';
import { TRIPS } from '@/Lib/graphql/queries/trip.query';
import { useDevice } from '@/Lib/hooks/useDevice';
import { useLocation } from '@/Lib/hooks/useLocation';
import { BasicTrip } from '@/Lib/types/trip';
import { useQuery } from '@apollo/client';
import { FC, useEffect, useState } from 'react';
import { RudderAnalytics } from '../ruddarstak/rudderanalytics';

export function getVisitedPages(): number[] | null {
  if (window) {
    const gwatVisitedPages = window.localStorage.getItem('gwat-visited-pages');
    if (gwatVisitedPages) {
      const parsedVisitedPages = JSON.parse(gwatVisitedPages);
      if (parsedVisitedPages) {
        return parsedVisitedPages;
      }
    }
  }
  return null;
}

const RecentDiscoveries: FC = () => {
  const { isMD } = useDevice();
  const [visitedPages, setVisitedPages] = useState<number[] | null>(null);
  const [orderedData, setOrderedData] = useState<BasicTrip[]>([]);

  const { loading, data } = useQuery<{ trips: { items: BasicTrip[] } }>(TRIPS, {
    variables: {
      ids: visitedPages?.map((item) => String(item)),
    },
    skip: !visitedPages,
  });

  useEffect(() => {
    setVisitedPages(getVisitedPages());
  }, []);

  useEffect(() => {
    if (data && visitedPages) {
      const ordered: BasicTrip[] = [];
      const unordered = data.trips.items;
      const firstFourVisitedPages = isMD
        ? visitedPages.slice(0, 4)
        : visitedPages;
      for (const visitedPage of firstFourVisitedPages) {
        const found = unordered.find((item) => item.id === visitedPage);
        if (found) {
          ordered.push(found);
        }
      }
      setOrderedData(ordered);
    }
  }, [data, visitedPages, isMD]);

  const { location } = useLocation();

  const clickOnRecent = (index: number, trip: BasicTrip) => {
    RudderAnalytics.recentDiscoveriesClicked({
      product_id: trip.id,
      product_name: trip.tripName,
      position: index + 1,
      country: location.country.name,
    });
  };

  return (
    <>
      {visitedPages &&
      Array.isArray(visitedPages) &&
      visitedPages.length > 0 ? (
        <section className="my-8 md:mx-10">
          {!loading && orderedData ? (
            <h1 className="text-3xl font-bold text-black mb-5 px-5 md:px-0">
              Your recent discoveries
            </h1>
          ) : null}
          <div
            className="grid xl:gap-2 lg:grid-cols-3 xl:grid-cols-4-1fr overflow-scroll xl:overflow-visible hide-webkit-scrollbar gap-6 px-5 md:px-0 py-2"
            style={{
              gridTemplateColumns: `repeat(${visitedPages.length}, 1fr)`,
            }}
          >
            {!loading && orderedData
              ? orderedData.map((trip, index) => (
                  <ProductCardSmall
                    trip={trip}
                    key={index}
                    click={() => clickOnRecent(index, trip)}
                  />
                ))
              : null}
          </div>
        </section>
      ) : null}
    </>
  );
};

export { RecentDiscoveries };
