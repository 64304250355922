import { GImage } from '../../g-image';
import { GStarRating } from '../../g-star-rating';
import Favorite from '../../favorite-button';
import { BasicTrip, Promotion, TripPrice } from '@/Lib/types/trip';
import { useLocation } from '@/Lib/hooks/useLocation';
import Link from 'next/link';
import { useQuery } from '@apollo/client';
import { GET_TRIP_PRICE } from '@/Lib/graphql/queries/trip.query';
import { Price } from './price';
import { useAtomValue } from 'jotai';
import { promotionsAtom } from '@/State/global/global.store';

type props = {
  trip: BasicTrip;
  click?: any;
};

function ProductCardSmall({ trip, click }: props): JSX.Element {
  const { data: tripData } = useQuery<{ trip: TripPrice }>(GET_TRIP_PRICE, {
    variables: {
      id: trip.id,
    },
  });
  const { currency } = useLocation();
  const { promotions: appliedPromotions, getDiscountAndReducedDeposit } =
    useAtomValue(promotionsAtom);

  const { discount, reducedDeposit } = getDiscountAndReducedDeposit
    ? getDiscountAndReducedDeposit(
        trip.promotions,
        appliedPromotions as Promotion[],
        trip,
        trip.prices[currency],
        tripData?.trip.deposit[currency] || 0,
        currency
      )
    : { discount: 0, reducedDeposit: 0 };

  return (
    <Link href={trip.url}>
      <a>
        <div
          onClick={click}
          className="flex rounded-md overflow-hidden"
          style={{
            minWidth: '299px',
            boxShadow: '2px 2px 6px rgb(0 0 0 / 15%)',
          }}
        >
          <div className="relative">
            <GImage
              path={trip.bannerImg}
              alt={trip.tripName}
              transformation="trip-cards"
              height="6.875rem"
              width="6.875rem"
              styles={{ minWidth: '6.875rem' }}
              classes="inline"
              hasLoadingBackground
              useGallery={true}
            />
            <Favorite
              deposit={
                tripData?.trip?.deposit[currency]
                  ? tripData?.trip?.deposit[currency] - reducedDeposit
                  : 0
              }
              price={trip.prices[currency] - discount}
              revenue={trip.prices[currency] - discount}
              type="smallcard"
              trip={trip}
              originalPrice={trip.prices[currency]}
              originalDeposite={
                tripData?.trip?.deposit[currency]
                  ? tripData?.trip?.deposit[currency]
                  : 0
              }
            />
          </div>
          <div className="flex-grow py-2.5 px-3 flex flex-col justify-between">
            <div className="text-base font-bold text-gray-900">
              {trip.tripName.length > 32
                ? `${trip.tripName.substring(0, 32)}...`
                : trip.tripName}
            </div>
            <div>
              {trip.reviews?.count ? (
                <div className="flex items-center mt-2 text-sm text-gray-600 space-x-1">
                  <div style={{ minWidth: '76px' }}>
                    <GStarRating
                      size={0.75}
                      rating={Number(trip.reviews?.rating || 0)}
                      translateY="-translate-y-0.5"
                    />
                  </div>
                  <span className="font-bold text-base leading-5">
                    {trip.reviews?.rating}
                  </span>
                  <span className="ml-1">({trip.reviews?.count})</span>
                </div>
              ) : null}
              <Price discount={discount} trip={trip} />
            </div>
          </div>
        </div>
      </a>
    </Link>
  );
}

export { ProductCardSmall };
