import { useLocation } from '@/Lib/hooks/useLocation';
import { BasicTrip } from '@/Lib/types/trip';
import { userLoginStatusAtom } from '@/State/global/global.store';
import { useAtomValue } from 'jotai';
import { currencySymbols } from 'data/currency';
import { getInternalName } from '@/Lib/helpers/get-internal-name';
import { useDynamicPrice } from '@/Lib/hooks/useDynamicPrice';
import { usePrice } from '@/Lib/hooks/usePrice';

type Props = {
  discount: number;
  trip: BasicTrip;
};

const Price = ({ discount, trip }: Props) => {
  const divisionInternalName = getInternalName(trip.division);
  const dynamicPrice = useDynamicPrice(divisionInternalName);
  const { price, discountedPrice } = usePrice({
    basePrice: trip.prices,
    dynamicPrice,
    discount,
  });
  const { currency, currencyShouldBeShown } = useLocation();
  const isUserLoggedIn = useAtomValue(userLoginStatusAtom);

  return (
    <>
      {isUserLoggedIn === true && (
        <div className="text-gray-600 mt-1.5 text-sm leading-5">
          {discount ? (
            <p>
              <span className="text-red-900 font-semibold mr-1 text-sm">
                From
              </span>
              <span className="line-through text-dark-700 text-2xs mr-1 font-semibold">
                {price}
              </span>
              <span className="text-red-900 font-semibold text-sm">
                {currencySymbols[currency]}
                {discountedPrice}
                {currencyShouldBeShown ? (
                  <span className="text-xs relative -top-px ml-0.5">
                    ({currency})
                  </span>
                ) : null}
              </span>
            </p>
          ) : null}
          {!discount ? (
            <p className="text-dark-700 text-sm font-semibold">
              From {currencySymbols[currency]}
              {price}
              {currencyShouldBeShown ? (
                <span className="text-xs relative -top-px ml-0.5">
                  ({currency})
                </span>
              ) : null}
            </p>
          ) : null}
        </div>
      )}
      {isUserLoggedIn === false && (
        <p className="text-xs font-semibold text-dark-700 underline mt-1.5">
          See Inclusions & Pricing
        </p>
      )}
      {isUserLoggedIn === null && (
        <div className="my-1 h-5 w-12 bg-light-900  animate-pulse "></div>
      )}
    </>
  );
};

export { Price };
